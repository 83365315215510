// Note that we import these values where needed as 'webAppConfig'
module.exports = {
  WE_VOTE_URL_PROTOCOL: 'https://',  // "http://" or "https://"
  WE_VOTE_HOSTNAME: 'quality.wevote.us',  // This should be without "https://"

  WE_VOTE_SERVER_ROOT_URL: 'https://api.wevoteusa.org/',
  WE_VOTE_SERVER_ADMIN_ROOT_URL: 'https://api.wevoteusa.org/admin/',
  WE_VOTE_SERVER_API_ROOT_URL: 'https://api.wevoteusa.org/apis/v1/',
  WE_VOTE_SERVER_API_CDN_ROOT_URL: 'https://cdn.wevoteusa.org/apis/v1/',

  ENABLE_NEXT_RELEASE_FEATURES: true,

  DEBUG_MODE: false,
  SHOW_TEST_OPTIONS: false,    // On the DeviceDialog and elsewhere

  LOG_RENDER_EVENTS: false,
  LOG_ONLY_FIRST_RENDER_EVENTS: false,
  LOG_HTTP_REQUESTS: false,
  LOG_ROUTING: false,
  LOG_SIGNIN_STEPS: false,  // oAuthLog function prints to console
  LOG_CORDOVA_OFFSETS: false,
  SHOW_CORDOVA_URL_FIELD: false,  // Only needed for debugging in Cordova

  // Use 1 or 0 as opposed to true or false
  test: {
    use_test_election: 0,
  },

  location: {
    text_for_map_search: '',
  },

  ENABLE_FACEBOOK: false,
  ENABLE_TWITTER: true,
  ENABLE_PAY_TO_PROMOTE: true,

  // These are publishable keys (not secret)
  FACEBOOK_APP_ID: '1097389196952441',
  FULL_STORY_ORG: '',
  GOOGLE_ANALYTICS_TRACKING_ID: '',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCM0vM1mzh56OuA5IfgW_U6Qd9jD99WH6M',
  GOOGLE_PEOPLE_API_KEY: 'AIzaSyDiwUn8ftYAJUqyygBPyQ4xfE85XX8JCWs', // WeVote.US live server
  GOOGLE_PEOPLE_API_CLIENT_ID: '598509332473-5ak51ndqa5ujgnmllk410qs55ouc73p2.apps.googleusercontent.com', // WeVote.US live server
  GOOGLE_RECAPTCHA_KEY: '',

  STRIPE_API_KEY: 'pk_test_bWuWGC3jrMIFH3wvRvHR6Z5H',
};

